import { Link } from "react-scroll";
import { information } from "../../information";

/*--------------------
* About Section
----------------------*/
export default function About() {
  return (
    <>
      <section className="section">
        <div className="container">
          <div className="grid lg:grid-cols-12 grid-cols-1 items-center">
            <div className="lg:col-span-6 text-center mb-[50px] lg:mb-0">
              <img
                className="mx-auto"
                src="img/aboutme-banner.png"
                title="Banner"
                alt="Banner"
              />
            </div>
            <div className="lg:col-span-6 lg:pl-12">
              <h3 className="font-[600] text-[26px] md:text-[40px] leading-[1.2] text-black mb-[25px]">
                We are passionate developers
              </h3>
              <p className="text-[16px] md:text-[18px]">
                Our team of highly skilled and experienced developers is dedicated to delivering
                innovative and transformative digital solutions. With expertise spanning front-end
                development, back-end engineering, and full-stack mastery, we are equipped to handle
                projects of any complexity and scale.
              </p>
              <div className="grid grid-cols-12 pt-5">
                <div className="col-span-6 sm:col-span-4">
                  <h5 className="text-[26px] text-orange-600 font-[600]">
                    {information.projectCompleted}
                  </h5>
                  <span>Project Completed</span>
                </div>
                <div className="col-span-6 sm:col-span-4">
                  <h5 className="text-[26px] text-orange-600 font-[600]">
                    {information.happyClient}
                  </h5>
                  <span>Happy Clients</span>
                </div>
              </div>
              <div className="pt-6">
                <Link
                  className="px-btn px-btn-theme mr-4"
                  to="contactus"
                >
                  Contact Us
                </Link>
                <Link
                  className="px-btn px-btn-dark"
                  to="portfolio"
                >
                  Our Portfolio
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
