import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ThankYou() {
  const navigate = useNavigate();
  const [remainSeconds, setRemainSeconds] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainSeconds((prevSecond) => prevSecond - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [remainSeconds]);

  useEffect(() => {
    const timer = setTimeout(() => {
      document.getElementById("goHomeButton").click();
    }, 5000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="h-screen flex items-center justify-center bg-gradient-to-b from-amber-50 to-lime-50">
      <div className="container flex flex-col items-center justify-center px-4 space-y-4 text-center bg-white py-10 w-[40rem] rounded-xl shadow-lg">
        <div className="space-y-2">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold tracking-tighter uppercase text-black">
            Thank you!
          </h1>
          <p className="text-xl pb-10 pt-2">
            We received your information. Expect to hear form us soon!
          </p>
        </div>
        <p className="">
          You will be redirected to the home page after {remainSeconds} seconds.
          <br />
          Click here if you are not redirected automatically
        </p>
        <div className="inline-block w-full max-w-sm pt-5">
          <button
            id="goHomeButton"
            className="hover:bg-black hover:text-white hover:scale-105 duration-200 bg-orange-600 text-white text-xl font-medium py-3 px-5"
            onClick={() => navigate("/")}
          >
            Go back to the home page
          </button>
        </div>
      </div>
    </div>
  );
}
