import ValueCard from "./valueCard";

export default function OurValue() {
  return (
    <>
      <section
        className="section overflow-hidden px-5 lg:px-20 xl:px-40 py-10 pb-20"
        id="values"
        data-scroll-data="4"
        style={{
          backgroundImage: "url(img/effect/bg-effect-1.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <h3 className=" font-[600] text-[40px] md:text-[60px] leading-[1.2] text-black text-center pt-[20px] lg:pt-[10px]">
          Our Values
        </h3>
        <p className=" font-[300] text-[22px] text-center pt-[20px] pb-[60px] lg:pt-[10px] lg:pb-[60px]">
          Principles that shape our approach to mentorship and education.
        </p>
        <div className="grid lg:grid-cols-12 grid-cols-1 gap-8 xl:gap-16 items-center max-w-3xl lg:max-w-[80rem] mx-auto">
          <ValueCard
            icon="fa-solid fa-arrows-spin"
            title="Agility - Quick Guidance, Enduring Wisdom"
            description="Never leave a learner waiting. Embrace agility in every interaction. Develop processes that provide rapid, insightful guidance with lasting benefits."
          />

          <ValueCard
            icon="fa-solid fa-hourglass-half"
            title="Endurance - Investing in Lifelong Learning"
            description="Commit to building lasting relationships with mentees and students. Focus on actions that yield significant, long-term educational growth."
          />

          <ValueCard
            icon="fa-solid fa-hand-holding-heart"
            title="Ownership - Empower Your Journey"
            description="Cultivate an ownership mindset in your learning journey. Take initiative, be accountable for your progress, and contribute actively to your personal and professional development."
          />

          <ValueCard
            icon="fa-solid fa-chart-line"
            title="Growth - Expanding Horizons"
            description="Offer scalable mentorship and courses that cater to individual needs while aligning with broader educational goals. Facilitate continuous learning and development."
          />

          <ValueCard
            icon="fa-solid fa-award"
            title="Excellence - Professional Integrity"
            description="Maintain the highest standards of professionalism in all educational interactions. Strive to exceed expectations and uphold a culture of respect, reliability, and excellence."
          />

          <ValueCard
            className="lg:col-span-4"
            icon="fa-solid fa-brain"
            title="Lifelong Learning - Curiosity and Development"
            description="Embrace a mindset of continuous learning. Stay open-minded, seek new knowledge, and innovate constantly. Collaborate with and learn from a community of ambitious learners and experts."
          />
        </div>
      </section>
    </>
  );
}
