import About from "../components/About/about";
import Banner from "../components/Banner/banner";
import BrandSlider from "../components/Brand/brand";
import Contact from "../components/Contact/contact";
import Footer from "../components/Footer/footer";
import Header from "../components/Header/header";
import ServiceList from "../components/Service/service";
import SkillList from "../components/Skills/skill";
import TheBlueDivider from "../components/TheBlueDivider/theBlueDivider";
import Project from "../components/Project/project";
import StudentFeedback from "../components/Feedback/studentFeedback";
import { workProjects } from "../information";
import { activities } from "../information";
import { motion } from "framer-motion";
import { useEffect } from "react";

export default function Home() {
  const home_menu = [
    { id: 0, name: "Services", link: "services", internal: true },
    { id: 1, name: "Skills", link: "skill", internal: true },
    { id: 2, name: "Profiles", link: "profiles", internal: false },
    { id: 3, name: "Projects", link: "projects", internal: true },
    { id: 4, name: "Activity", link: "activity", internal: true },
    { id: 5, name: "Contact", link: "contactus", internal: true },
  ];

  useEffect(() => {
    if(!window.umami) { console.log("Umami not found"); return }
    let sections = document.querySelectorAll('section')
    let sectionCallback = (entries) => {
      entries.forEach(section => {
        if (section.isIntersecting) {
          if (!section.target.id) { return }
          window.umami.track(`See ${section.target.id} section`)
        }
      })
    }

    let observer = new IntersectionObserver(sectionCallback)
    sections.forEach(section => {
      observer.observe(section)
    })

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      exit={{ opacity: 1 }}
    >
      <Header menu={home_menu} />
      <main className="wrapper">
        <Banner />
        <BrandSlider />
        <About />
        <ServiceList />
        <TheBlueDivider
          title="Check out our team members!"
          buttonText="See our members"
          rightButton
          to="/profiles"
        />
        <SkillList />
        <TheBlueDivider
          title="Interested in working with us?"
          buttonText="Contact Now"
          rightButton
          internal
          to="contactus"
        />
        <Project projectDetails={workProjects} sectionTitle="Our Projects" buttonText="View Project" id="projects" scrollData="3" />
        <TheBlueDivider
          title="Join our team and create something interesting!"
          buttonText="Join Us Now!"
          anchor
          href="https://join.phuquocdevs.com/"
        />
        <Project projectDetails={activities} sectionTitle="Our Group Activities" buttonText="View More Detail" id="activity" scrollData="4" />
        <StudentFeedback id="studentFeedback" />
        <Contact />
      </main>
      <Footer />
    </motion.div>
  );
}
