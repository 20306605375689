import { studentFeedbacks } from "../../information";

const Feedback = ({ name, image, feedback }) => {
  return (
    <div className="p-5 border-2 border-slate-900 shadow-slate-900 shadow-[0_0_0_0] ease-in duration-300 hover:shadow-[5px_5px_0_0]">
      {/* <div className="p-5 border shadow-md"> */}
      <div className="flex items-center mb-6">
        <img src={image} alt={name} className="w-10 rounded-full mr-4 h-auto max-w-full" />
        <div>
          <h4 className="font-bold text-lg">{name}</h4>
          <p>⭐⭐⭐⭐⭐</p>
        </div>
      </div>
      <p>{feedback}</p>
    </div>
  );
};

export default function StudentFeedback() {
  let feedbackColumns = [];
  studentFeedbacks.forEach((feedback, index) => {
    let columnNumber = index % 3
    if (!feedbackColumns[columnNumber]) {
      feedbackColumns[columnNumber] = []
    }
    feedbackColumns[columnNumber].push(feedback);
  })

  return (
    <>
      <section
        data-scroll-data="5"
        id="feedback" 
        className="section">
        <div className="container">
          <div className="grid section-heading">
            <div className="lg:col-span-6 text-center mx-auto">
              <h3>
                <span>STUDENT FEEDBACK</span>
              </h3>
              <p className="mt-4 text-2xl text-gray-700">
                What do students think about PhuQuocDevs?
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
            {feedbackColumns.map((col, i) => (
              <div className="grid gap-4" key={i}>
                {col.map((val, i) => (
                  <Feedback
                    key={i}
                    name={val.name}
                    image={val.avatar}
                    feedback={val.feedback} />
                ))}
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}