export default function OurMission() {
  return (
    <>
      <section
        className="section bg-slate-50"
        id="mission"
        data-scroll-data="3"
      >
        <h3 className=" font-[600] text-[40px] md:text-[60px] leading-[1.2] text-black text-center pt-[30px] pb-[30px] lg:pt-[20px] lg:pb-[30px]">
          Our Mission
        </h3>
        <div className="flex flex-col lg:flex-row gap-8 xl:gap-14 max-w-3xl lg:max-w-[80rem] p-5 xl:py-10 xl:px-32 xl:max-w-[95rem] mx-auto">
          <div className="space-y-14">
            <div className="feature-box-02 flex flex-col lg:h-[20rem] xl:min-h-[10rem]">
              <h4 className="font-[600] text-black text-[22px] md:text-[24px] pb-2">
                Connecting Developers with Mentors Worldwide
              </h4>
              <p className="text-[16px] md:text-[18px] text-justify">
                We believe that mentorship should be accessible to all people, regardless of
                location or financial standing. Our mission is to foster a greater sense of
                collaboration and inclusiveness in the technical industry by providing a free and
                open-source platform to facilitate mentorship connections. Through our efforts, we
                aim to connect developers with mentors worldwide
              </p>
            </div>
            <div className="feature-box-02 flex flex-col lg:h-[20rem] xl:min-h-[10rem]">
              <h4 className="font-[600] text-black text-[22px] md:text-[24px] pb-2">
                Empowering Aspiring Developers
              </h4>
              <p className="text-[16px] md:text-[18px] text-justify">
                We focus around empowering aspiring developers. We recognize that learning to code
                and landing that first job can be daunting. By offering mentorship, we provide
                guidance, support, and encouragement to those navigating their coding journey. Our
                goal is to help them succeed and thrive in the tech world.
              </p>
            </div>
          </div>
          <div className="space-y-14">
            <div className="feature-box-02 flex flex-col lg:h-[20rem] xl:min-h-[10rem]">
              <h4 className="font-[600] text-black text-[22px] md:text-[24px] pb-2">
                Building a Stronger Community
              </h4>
              <p className="text-[16px] md:text-[18px] text-justify">
                Our team is committed to building a stronger community. We believe that mentorship
                not only benefits individual developers but also contributes to a more collaborative
                and supportive industry. By fostering connections, sharing knowledge, and lifting
                each other up, we create a positive environment for growth and innovation.
              </p>
            </div>
            <div className="feature-box-02 flex flex-col lg:h-[20rem] xl:min-h-[10rem]">
              <h4 className="font-[600] text-black text-[22px] md:text-[24px] pb-2">
                Facilitating Career Opportunities
              </h4>
              <p className="text-[16px] md:text-[18px] text-justify">
                Our commitment extends beyond mentorship. We strive to equip students with practical
                skills that lead to job opportunities. By providing real-world projects, interview
                preparation, and networking events, we empower our learners to confidently step into
                the tech workforce. Our ultimate goal is to see our students thrive in fulfilling
                careers after completing our program.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
