/*--------------------
* Service Section
----------------------*/
function Service(props) {
  return (
    <>
      <div className="lg:col-span-6">
        <div className={props.id.main + " feature-box-01"}>
          <div className="icon">
            <i className={props.id.icon}></i>
          </div>
          <div className="feature-content">
            <h5>{props.title}</h5>
            <p>{props.description}</p>
          </div>
        </div>
      </div>
    </>
  );
}

/*--------------------
* Service List Section
----------------------*/
export default function ServiceList() {
  const service_list = [
    {
      id: { main: "bg-2", icon: "fas fa-server" },
      title: "Backend Development",
      description:
        "We build robust and scalable backends that power your web applications and ensure seamless data management.",
    },
    {
      id: { main: "bg-1", icon: "fas fa-columns" },
      title: "Frontend Development",
      description:
        "We create engaging and responsive user interfaces that provide a delightful experience for your users.",
    },
    {
      id: { main: "bg-6", icon: "fas fa-laptop-code" },
      title: "Programming Courses",
      description:
        "We empower individuals with in-demand programming skills through our comprehensive and industry-aligned courses.",
    },
    {
      id: { main: "bg-5", icon: "fas fa-toolbox" },
      title: "Custom Tools",
      description:
        "We develop custom software solutions and tools tailored to your unique business needs.",
    },
  ];
  return (
    <>
      <section
        data-scroll-data="1"
        id="services"
        className="section services-section bg-gray"
        style={{
          backgroundImage: "url(img/effect/bg-effect-1.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="grid section-heading">
            <div className="lg:col-span-6 text-center mx-auto">
              <h3>
                <span>Our Services</span>
              </h3>
            </div>
          </div>
          <div className="grid lg:grid-cols-12 md:grid-cols-2 gap-5 grid-cols-1 items-center">
            {service_list.map((val, i) => {
              return (
                <Service
                  key={i}
                  id={val.id}
                  title={val.title}
                  description={val.description}
                />
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}
