import Contact from "../components/Contact/contact";
import Footer from "../components/Footer/footer";
import Header from "../components/Header/header";
import TeamInformation from "../components/Information/teamInformation";
import OurMission from "../components/OurMIssion/ourMission";
import OurTeam from "../components/OurTeam/ourTeam";
import OurValue from "../components/OurValue/ourValue";
import TheBlueDivider from "../components/TheBlueDivider/theBlueDivider";
import { motion } from "framer-motion";
import { useEffect } from "react";

export default function Profiles() {
  const profile_menu = [
    { id: 1, name: "Team", link: "team", internal: true },
    { id: 2, name: "Members", link: "members", internal: true },
    { id: 3, name: "Mission", link: "mission", internal: true },
    { id: 4, name: "Values", link: "values", internal: true },
    { id: 5, name: "Contact", link: "contactus", internal: true },
  ];

  useEffect(() => {
    if(!window.umami) { console.log("Umami not found"); return }
    let sections = document.querySelectorAll('section')
    let sectionCallback = (entries) => {
      entries.forEach(section => {
        if (section.isIntersecting) {
          if (!section.target.id) { return }
          window.umami.track(`See ${section.target.id} section`)
        }
      })
    }

    let observer = new IntersectionObserver(sectionCallback)
    sections.forEach(section => {
      observer.observe(section)
    })

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      exit={{ opacity: 1 }}
    >
      <Header menu={profile_menu} />
      <main className="wrapper">
        <OurTeam />
        <TeamInformation />
        <OurMission />
        <TheBlueDivider
          title="Need more information? Contact us now!"
          buttonText="Contact Now"
          rightButton
          internal
          to="contactus"
        />
        <OurValue />
        <Contact />
      </main>
      <Footer />
    </motion.div>
  );
}
