export default function ValueCard({ icon, title, description, className }) {
  return (
    <>
      <div className="lg:col-span-4">
        <div className="bg-2 feature-box-01 lg:min-h-[23rem] xl:min-h-[25.5rem] flex flex-col items-center space-y-3 text-center">
          <div className="icon">
            <i className={icon}></i>
          </div>
          <div className="feature-content">
            <h5>{title}</h5>
            <p className="text-justify">{description}</p>
          </div>
        </div>
      </div>
    </>
  );
}
