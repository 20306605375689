import { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { ImagePopup } from "../Image/image";

function ProjectModal(props) {
  const [showImage, setShowImage] = useState(0);
  const closeImage = () => {
    setShowImage(0);
  };

  return (
    <>
      <div className="px-modal mfp-hide">
        <div className="single-project-box">
          <div className="grid grid-cols-12 gx-3">
            <div className="col-span-12 md:col-span-7 mb-10 md:mb-0">
              <div className="grid grid-cols-2 gap-2">
                {props.projectDetails.images.map((img, i) => {
                  return (
                    <div
                      key={`image_${i}`}
                      className="col-span-1"
                    >
                      <img
                        className="border"
                        src={img}
                        title=""
                        alt=""
                        onClick={() => setShowImage(img)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-span-12 md:col-span-5 md:pl-10 lg:pl-14">
              <h4 className="font-[600] text-[25px] text-black mb-4 pb-4 border-b">
                {props.projectDetails.title}
              </h4>
              <p className="mb-3">{props.projectDetails.description}</p>
              <p>{props.projectDetails.subDescription}</p>
              {props.projectDetails.details && (
                <ul className="m-0 p-0 pt-7 list-none">
                  {props.projectDetails.details.map((detail, i) => {
                    return (
                      <li
                        key={`detail_${i}`}
                        className="flex py-2"
                      >
                        <span className="w-[100px] font-[600] text-black">{detail.title}:</span>
                        {detail?.options?.clickable ? (
                          <a
                            className="text-blue-600 underline"
                            href={detail.description}
                            target="_blank" rel="noreferrer"
                            data-umami-event={`Click url: ${detail.description}`}
                          >
                            {detail.description}
                          </a>
                        ) : (
                          <span>{detail.description}</span>
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
            <button
              className="px-close"
              onClick={props.closePopup}
            >
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>

      {showImage !== 0 ? (
        <>
          <ImagePopup
            closeImage={closeImage}
            img={showImage}
          />
        </>
      ) : null}
    </>
  );
}

export default function Project({ projectDetails, sectionTitle, buttonText, id, scrollData }) {
  const [showModal, setShowModal] = useState(0);
  const closePopup = () => {
    setShowModal(0);
  };

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    setIsSmallScreen(window.innerWidth <= 768);
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const viewDetail = (projectTitle, index) => {
    // Show the popup
    setShowModal(index + 1);

    const sanitizedTitle = projectTitle.includes('-') ? projectTitle.split('-')[0] : projectTitle;
    // Track the project view event
    if (!window.umami) { return }
    window.umami.track(`View detail: ${sanitizedTitle}`);
  };

  const ProjectImage = ({ index, images, projectName }) => (
    <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 mb-10">
      <div
        className="portfolio-img"
        onClick={() => viewDetail(projectName, index)}
      >
        <img
          src={images[0]}
          title=""
          alt=""
        />
        <Link
          to="#"
          className="gallery-link gallery-link-icon pointer-events-none"
        >
          <i className="fas fa-arrow-right"></i>
        </Link>
      </div>
    </div>
  );
  const ProjectText = ({ index, project }) => (
    <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
      <div className="portfolio-text">
        <h6>
          <span>{project.title}</span>
        </h6>
        <h4
          className="cursor-pointer"
          onClick={() => viewDetail(project.title, index)}
        >
          {project.subDescription}
        </h4>
        <p>{project.description}</p>
        <div className="btn-bar">
          <div
            className="px-btn px-btn-theme px_modal"
            onClick={() => viewDetail(project.title, index)}
          >
            {buttonText}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <section
        data-scroll-data={scrollData}
        id={id}
        className="section bg-orange-50"
      >
        <div className="container">
          <div className="grid section-heading">
            <div className="lg:col-span-6 text-center mx-auto">
              <h3>
                <span>{sectionTitle}</span>
              </h3>
            </div>
          </div>
          <div className="lightbox-gallery portfolio-box">
            {projectDetails.map((project, index) => (
              <div
                className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center"
                key={index}
              >
                {isSmallScreen ? (
                  <>
                    <ProjectImage
                      images={project.images}
                      projectName={project.title}
                      index={index}
                    />
                    <ProjectText
                      project={project}
                      index={index}
                    />
                  </>
                ) : index % 2 === 0 ? (
                  <>
                    <ProjectImage
                      images={project.images}
                      projectName={project.title}
                      index={index}
                    />
                    <ProjectText
                      project={project}
                      index={index}
                    />
                  </>
                ) : (
                  <>
                    <ProjectText
                      project={project}
                      index={index}
                    />
                    <ProjectImage
                      images={project.images}
                      projectName={project.title}
                      index={index}
                    />
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      {showModal ? (
        <>
          <ProjectModal
            closePopup={closePopup}
            projectDetails={projectDetails[showModal - 1]}
          ></ProjectModal>
        </>
      ) : null}
    </>
  );
}
