import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

/**
 * title: The title of the divider.
 * buttonText: The text in the button.
 * internal: true if the link is on the same page. False by default
 * to: the path or id of the component to jump to.
 * anchor: if the button is a link to external site.
 * href: set the href if button is anchor.
 * rightButton: if true, the button is on the right side. False by default.
 */
export default function TheBlueDivider({
  title,
  to,
  buttonText,
  internal,
  rightButton,
  anchor,
  href,
}) {
  let linkComponent;
  if (anchor) {
    linkComponent = (
      <a
        className="px-btn px-btn-theme2"
        href={href}
        target="_blank"
        rel="noreferrer"
      >
        {buttonText}
      </a>
    );
  } else if (internal) {
    linkComponent = (
      <ScrollLink
        className="px-btn px-btn-theme2"
        to={to}
      >
        {buttonText}
      </ScrollLink>
    );
  } else {
    linkComponent = (
      <RouterLink
        className="px-btn px-btn-theme2"
        to={to}
      >
        {buttonText}
      </RouterLink>
    );
  }

  return (
    <section className="py-10 bg-slate-900">
      <div className="container">
        <div className="grid grid-cols-12 gap-5 items-center">
          {rightButton ? (
            <>
              <div className="col-span-12 lg:col-span-8 md:col-span-7 text-center md:text-left">
                <h4 className="text-[25px] md:text-[30px] lg:text-[35px] m-0 text-white font-[600]">
                  {title}
                </h4>
              </div>
              <div className="col-span-12 lg:col-span-4 md:col-span-5 text-center text-md-end">
                {linkComponent}
              </div>
            </>
          ) : (
            <>
              <div className="col-span-12 lg:col-span-4 md:col-span-7 text-center md:text-left">
                {linkComponent}
              </div>
              <div className="col-span-12 lg:col-span-8 md:col-span-5 text-center text-md-end">
                <h4 className="text-[25px] md:text-[30px] lg:text-[35px] m-0 text-white font-[600]">
                  {title}
                </h4>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
}
