import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";

export function ImagePopup(props) {
  return (
    <div className="px-modal mfp-hide">
      <div className="single-project-box">
        <InnerImageZoom
          className="border mx-auto"
          src={props.img}
          zoomType="hover"
          zoomPreload={true}
          title=""
          alt=""
        />
        <button
          className="px-close"
          onClick={props.closeImage}
        >
          <i className="fa fa-times"></i>
        </button>
      </div>
    </div>
  );
}
