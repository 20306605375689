/*--------------------
* Contact Section
----------------------*/

import { information } from "../../information.js";

export default function Contact() {
  return (
    <>
      <section
        data-scroll-data="6"
        id="contactus"
        className="section contactus-section bg-slate-900"
        style={{
          backgroundImage: "url(img/effect/bg-effect-3.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="grid section-heading">
            <div className="lg:col-span-6 text-center mx-auto">
              <h3>
                <span className="text-white">Let's connect</span>
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-12">
            <div className="col-span-12 lg:col-span-6 ">
              <div className="p-[25px] md:p-[35px] bg-white">
                <h6 className="text-[32px] font-[600] text-black mb-[5px]">Get in touch</h6>
                <p className="text-[18px] mb-[30px]">
                  Our friendly team would love to hear from you.
                </p>
                <form
                  id="contact-form"
                  method="POST"
                  action="/submit"
                >
                  <div className="grid grid-cols-12 gap-3">
                    <div className="col-span-12 md:col-span-6">
                      <div className="form-group">
                        <label className="form-label">First name</label>
                        <input
                          name="name"
                          id="name"
                          placeholder="Name *"
                          className="form-control"
                          type="text"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <div className="form-group">
                        <label className="form-label">Your Email</label>
                        <input
                          name="email"
                          id="email"
                          placeholder="Email *"
                          className="form-control"
                          type="email"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="form-group">
                        <label className="form-label">Subject</label>
                        <input
                          name="subject"
                          id="subject"
                          placeholder="Subject *"
                          className="form-control"
                          type="text"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="form-group">
                        <label className="form-label">Your message</label>
                        <textarea
                          name="message"
                          id="message"
                          placeholder="Your message *"
                          rows="4"
                          className="form-control"
                          required
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="send">
                        <button
                          className="px-btn px-btn-theme2"
                          value="Send"
                          type="submit"
                          data-umami-event={`Sending the contact form`}
                        >
                          {" "}
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6 flex">
              <div className="lg:max-w-[410px] w-full lg:ml-auto pt-[50px] lg:pt-0">
                <div className="pb-10">
                  <img
                    className="w-full"
                    src="img/email_2.jpg"
                    title=""
                    alt=""
                  />
                </div>
                <ul className="contact-infos">
                  <li>
                    <div className="icon bg-1">
                      <i className="fas fa-envelope"></i>
                    </div>
                    <div className="col">
                      <h5>Phone</h5>
                      <p>{information.phone}</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon bg-3">
                      <i className="fas fa-map-marker-alt"></i>
                    </div>
                    <div className="col">
                      <h5>Based in</h5>
                      <p>{information.address}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
