export const workProjects = [
  {
    title: "Cooperation with Livesport",
    description: `Established in 2006, Livesport is a Czech technology company specializing in providing sports information. They develop websites and applications that provide fast news from more than 35 sports, along with several other products, all with a common denominator: the delivery of information in real time using state-of-the-art technology. Our project was a Chrome extension for an internal team, built with Vue.js, Express.js, OpenAPI, Prisma, MySQL, Kubernetes.`,
    subDescription: "Cooperation with Livesport",
    images: ["img/projects/livesport_coop/livesport_red_on_white_big.jpg"],
    details: [
      {
        title: "Type",
        description: "Chrome Extension",
      },
      {
        title: "Livesport",
        description: "https://livesport.eu/",
        options: { clickable: true }
      },
      {
        title: "Technology",
        description: "Vue.js, Express.js, Prisma, OpenAPI, MySQL, Kubernetes",
      },
    ],
  },
  {
    title: "Cooperation with Inset",
    description: `INSET s.r.o., a company specializing in innovative measurement solutions, IoT systems, long-term and continuous monitoring of buildings and construction sites through its wireless sensors. Providing a reliable solution to ensure the safety and stability of structures in remote or hard-to-reach areas, even in places without electricity or data connection. Our task for INSET was developing internal tools for managing remote sensors.`,
    subDescription: "Cooperation with Inset",
    images: ["img/projects/inset_coop/inset_green_on_white_big.png"],
    details: [
      {
        title: "Type",
        description: "API, microservices, web UI",
      },
      {
        title: "Inset",
        description: "https://www.inset.com/en",
        options: { clickable: true }
      },
      {
        title: "Technology",
        description: "Node.js, Express.js, MQ, MUI5",
      },
    ],
  },
  {
    title: "Website for Atina Hotel",
    description:
      "The hotel website showcases accommodations, amenities, and services, providing essential information for a seamless stay experience",
    subDescription: "Atina Hotel",
    images: [
      "img/projects/atina/image1.jpg",
      "img/projects/atina/image3.jpg",
      "img/projects/atina/image4.jpg",
      "img/projects/atina/image2.jpg",
    ],
    details: [
      {
        title: "Type",
        description: "Website",
      },
      {
        title: "Langages",
        description: "PHP, HTML, CSS, Javascript",
      },
      {
        title: "Country",
        description: "Vietnam",
      },
      {
        title: "Live URL",
        description: "https://www.atinahomestay.com",
        options: { clickable: true }
      },
    ],
  },
  {
    title: "Phu Quoc Villas - Newest villas information in Phu Quoc",
    description:
      "Phu Quoc Villas! Find your dream island escape. Browse unique villas, stunning beachfront properties, and hidden gems - all with complete amenities and easy booking.",
    subDescription: "Phu Quoc Villas",
    images: [
      "img/projects/phuquocvillas/phuquoc_1.jpg",
      "img/projects/phuquocvillas/phuquoc_3.jpg",
      "img/projects/phuquocvillas/phuquoc_4.jpg",
      "img/projects/phuquocvillas/phuquoc_2.jpg",
    ],
    details: [
      {
        title: "Type",
        description: "Website",
      },
      {
        title: "Langages",
        description: "PHP, HTML, CSS, Javascript",
      },
      {
        title: "Country",
        description: "Vietnam",
      },
      {
        title: "Live URL",
        description: "https://phuquoc.villas/",
        options: { clickable: true }
      },
    ],
  },
  {
    title: "Web Scraper - Custom-built tool for web scaping",
    description:
      "We developed a small webs craping system for checking cancelled theater tickets, to help our client book rare and valuable seats as quickly as possible. We used puppeteer, node.js, docker, pastebin API and email notifications, and we set up a 24/7 uptime monitoring system using an open-source software.",
    subDescription: "Theater tickets - Web Scraper",
    images: ["img/projects/web_scraper/1.jpg", "img/projects/web_scraper/2.jpg"],
    details: [
      {
        title: "Type",
        description: "Custom Tool",
      },
      {
        title: "Technology",
        description: "Node.js, Docker, PasteBin API",
      },
    ],
  },
  {
    title: "Multiplayer Snake game",
    description: "Snake game with multiplayer implemented with Phaser and webRTC",
    subDescription: "Multiplayer Snake game",
    images: [
      "img/projects/multiplayer_snake_game/1.jpg",
      "img/projects/multiplayer_snake_game/2.jpg",
      "img/projects/multiplayer_snake_game/3.jpg",
      "img/projects/multiplayer_snake_game/4.jpg",
    ],
    details: [
      {
        title: "Type",
        description: "Game",
      },
      {
        title: "Technology",
        description: "Phaser.js, JavaScript, webRTC",
      },
    ],
  },
];

export const information = {
  projectCompleted: workProjects.length,
  happyClient: "3",
  companyName: "PhuQuocDevs",
  location: "Phu Quoc, Vietnam",
  phone: "+84 968 798 828",
  address: "Phu Quoc, Vietnam",
};

export const activities = [
  {
    title: "Advent of Code",
    description: `Advent of Code is an annual coding event that challenges participants with a series of programming puzzles, released daily from December 1st to 25th. Solve these puzzles using any language and collaborate with teammates to sharpen your skills. From algorithms to data structures, Advent of Code offers an opportunity to push your coding abilities to new heights.

    Join our team and be a part of this exciting holiday tradition. Experience the joy of problem-solving, the thrill of collective achievement, and the growth that comes from tackling Advent of Code head-on.`,
    subDescription: `Advent of Code: A Coding Challenge Like No Other`,
    images: ["img/activities/aoc.jpg", "img/activities/phuquocdevs_aoc_2023.png"],
  },
  {
    title: "Robo Duck Tournament",
    subDescription: "Robo Duck Tournament - Create your Duck and fight for the prize!",
    description:
      "In Robo Duck Battles, participants must program their own robotic ducks to compete in exhilarating combat. These ducks, armed with advanced AI and weaponry, engage in intense battles where strategic programming determines victory. Spectators will witness the ingenuity of each creator as their mechanized marvels clash for supremacy in this thrilling showcase of programming skill.",
    images: [
      "img/activities/ai-duck.jpg",
      "img/activities/born-duck.jpg",
      "img/activities/duck-tournament.jpg",
      "img/activities/duck-tournament-image.jpg",
    ],
  },
  {
    title: "Coding Escape - Escape Room Online",
    subDescription: "Coding Escape - Escape Room Online",
    description:
      "Coding Escape. This new challenge-based solution combines programming, creativity and non-coding exercises. This multiplayer experience allows teams to work together remotely, as they embark on a fun and unique adventure.",
    images: ["img/activities/coding_escape.png", "img/activities/escape-room.png"],
  },
  {
    title: "Clash of Code Tournament",
    subDescription: "Clash of Code Tournament",
    description:
      "Clash of Code is the ultimate coding showdown where participants compete head-to-head to solve challenges in real-time. It's a test of skill, speed, and strategy as coders race to write the best code. Victory goes to the coder with the fastest fingers and the sharpest mind in this adrenaline-pumping clash of code!",
    images: [
      "img/activities/clash_of_code_logo_art.jpg",
      "img/activities/coc-tournament-results.jpg",
    ],
  },
  {
    title: "TheCatch CTF",
    subDescription: "TheCatch CTF - Work as a team and capture the flag",
    description:
      "TheCatch CTF is the ultimate cybersecurity challenge where players compete to solve puzzles and tasks, testing their hacking skills. It's a thrilling battle to climb the leaderboard and prove your prowess in cybersecurity. Join the action-packed adventure of TheCatch CTF and show off your skills!",
    images: ["img/activities/thecatch2023.png", "img/activities/thecatch2022.png"],
    details: [
      {
        title: "URL",
        description: "https://thecatch.cz",
        options: { clickable: true }
      },
    ]
  },
];

export const clientLogos = ["img/clients/livesport.png", "img/clients/inset.png"];

export const studentFeedbacks = [
  {
    name: "Vanditha",
    avatar: "img/avatars/avatar-4.jpg",
    feedback: `PhuQuocDevs has been an invaluable study group for enhancing my professional skills. I am extremely grateful to have discovered this community. Our mentor is outstanding, providing insightful guidance and fostering an environment of growth. The team is equally impressive, characterized by a collaborative spirit and a shared commitment to learning and development.`,
  },
  {
    name: "Quynh Le",
    avatar: "img/avatars/avatar-1.jpg",
    feedback: `For me, Phuquocdevs was the first place that helped me see the world. Mr. Peter's dedication, enthusiasm and kindness are what made me become a member of Phuquocdevs, where I can contribute ideas, discuss with tech friends and receive guidance from Mr. Peter.`,
  },
  {
    name: "Hung Phu",
    avatar: "img/avatars/avatar-2.jpg",
    feedback: `PhuQuocdevs is a community where I can freely share the technical things I know, besides I also learn a lot of new things from everyone in the group, especially everyone uses English. The activities are also very exciting and always innovative, not boring. For a long time, I hoped that in the future I could work or at least operate in such an environment. There were all the elements I expected such as 100% English, and everyone talked about academics, programming. There are many great devs in the group, and I hope in the future I will achieve my goals like them.`,
  },
  {
    name: "Thao Vy",
    avatar: "img/avatars/avatar-5.jpg",
    feedback: `For me, PhuQuocDevs is so much more than a typical study group I've experienced before. Here, we have weekly coding sessions, mentorship opportunities and even access to a wealth of learning resources. I'm hoping to gain not just technical skills, but valuable insights from the people in this community.`,
  },
  {
    name: "Khai Minh",
    avatar: "img/avatars/avatar-3.jpg",
    feedback: `I'm really grateful to Mr. Pete and PhuQuocDevs community for many valuable lessons. I have been in PhuQuocDevs for more than 3 years and I learned many things that I believe it would cost me much more than 3 years to learn. Therefore, I really want to contribute to the community. PhuQuocDevs is a great community to learn and share and I'm proud to be a part of the group. Hopefully the group will grow more in the future, with more people, more lessons and more valuable sharing.`,
  },
  {
    name: "Evan",
    avatar: "img/avatars/avatar-1.jpg",
    feedback: `Its a great learning platform with lots of resources. I find some of the guys pretty much keen to knowledge and hungry for knowledge sharing and gathering. You'll be forced to push your learnings automatically by this.`,
  }
]

export const programmerList = [
  {
    name: "Peter",
    avatar: ["img/programmers/peter-ai.jpeg", "img/programmers/peter.png"],
    skills: ["fab fa-node", "fab fa-php", "fab fa-docker"],
    position: "Mentor",
    description: "Peter enjoys taking up challenges, which is why he travels the world and works on top products, and currently mentors a group of young IT talents. Instead of reinventing the wheel, he prefers to create new things, unexpected elegant solutions, always seeking deep meaning. With his programming experience in PHP since 2008 and Node.js since 2015, Peter is skilled in everything Docker, a self-hoster addict, open-source lover, and an advocate for agile methodologies. On top of that, he is currently dabbling with Rust. Don't ask him about stirling engines unless you are ready for an hours-long debate.",
  },
  {
    name: "Khai Minh",
    avatar: ["img/programmers/minh-ai.jpg", "img/programmers/minh.jpg"],
    skills: ["fab fa-node", "fab fa-vuejs", "fab fa-python", "fab fa-docker"],
    position: "Developer",
    description: "Minh is a junior JS developer on his way to become an expert in software development. His tech stack is JavaScript, MySQL, ExpressJS, VueJS, NodeJS and he has the potential skills of a junior developer. He has a great fondness for programming and always want to improve his IT skills. Besides programming, Minh likes playing guitar and listening to music.",
  },
  {
    name: "Thanh Hung",
    avatar: ["img/programmers/hung-ai.png", "img/programmers/hung.jpg"],
    skills: ["fab fa-node", "fab fa-vuejs", "fab fa-docker"],
    position: "Developer",
    description: "Hung, a junior developer, is wholeheartedly committed to mastering backend development. He uses JavaScript, Node.js, and Vue.js to solve problems and improve his skills. His goal is to become an expert in backend web development. Off the keyboard, he enjoys playing basketball and video games.",
  },
  {
    name: "Phi Truong",
    avatar: ["img/programmers/truong-ai.jpeg", "img/programmers/truong.png"],
    skills: ["fab fa-node", "fab fa-vuejs", "fab fa-react", "fab fa-python", "fab fa-golang", "fab fa-docker"],
    position: "Developer",
    description: "Truong is a developer learning, growing, and continually improving his skills to become Fullstack developer and DevOps. He works with Python, Node.js, Golang, Docker, Vue, and React to solve problems and create applications. Away from coding, Truong enjoys the simple pleasures of playing guitar and taking photographs to capture moments. He always seeking to learn from others and better himself.",
  },
  {
    name: "Hung Phu",
    avatar: ["img/programmers/phu-ai.jpeg", "img/programmers/phu.jpg"],
    skills: ["fab fa-node","fab fa-php", "fab fa-react", "fab fa-python", "fab fa-java"],
    position: "Developer",
    description: "Phu is a backend developer, he is focused on becoming a Technical Leader or DevOps in the next 10 years. He often works with Javascript, Python, C#/.Net, Java and React to create applications and improve his skills. He is interested in AI and Machine Learning projects. Besides, he is always researching and learning more knowledge about project management and project architecture such as Agile, Waterfall or Design Pattern, OOP. In his free time, he often reads books, listens to music, or plays games with friends.",
  }
]
