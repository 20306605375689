import { createBrowserRouter } from "react-router-dom";
import Root from "./components/Root/root";
import Home from "./pages/Home";
import Profiles from "./pages/Profiles";
import ThankYou from "./pages/ThankYou";

export const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/profiles", element: <Profiles /> },
      { path: "/thankyou", element: <ThankYou /> },
    ],
  },
]);
