import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useState } from "react";
import { programmerList } from "../../information";

function ProgrammerCard(props) {
  let displayedSkills = props.skills.slice(0, 4);
  let extraSkills = props.skills.length - 4;
  return (
    <>
      <div
        className="feature-box-03 flex flex-col cursor-pointer"
        onClick={props.onClick}
        title="Reveal the secret"
      >
        <div className="overflow-hidden mx-auto mb-2 border-2 border-solid border-slate-900">
          <img
            src={props.avatar}
            title="Reveal the secret"
            alt={props.name}
          />
        </div>
        <div className="flex flex-col pl-[0px]">
          <h6 className="font-[600] text-slate-900 m-0">{props.name}</h6>
          <span className="text-[14px]">{props.position}</span>
          <div className="flex flex-row flex-wrap">
            {displayedSkills.map((icon, index) => (
              <div
                key={index}
                className="icon mr-2"
              >
                <i className={icon}></i>
              </div>
            ))}
            {extraSkills > 0 && (
              <span className="text-xs text-gray-500 pt-1">+{extraSkills} more</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function ProgrammerDetail(props) {
  return (
    <>
      <div className="px-modal mfp-hide">
        <div
          className="single-project-box"
          style={{ maxWidth: "950px" }}
        >
          <div className="grid grid-cols-12 gx-3">
            <div className="col-span-12 md:col-span-6 mb-10 md:mb-0">
              <div className="overflow-hidden mx-auto mb-2 border-2 border-solid border-slate-900">
                <img
                  src={props.programmerDetails.avatar[1]}
                  title={props.programmerDetails.name}
                  alt={props.programmerDetails.name}
                />
              </div>
            </div>
            <div className="col-span-12 md:col-span-6 md:pl-10 lg:pl-14">
              <div className="flex justify-between mb-4 pb-4 border-b">
                <div className="flex flex-col">
                  <h4 className="font-[600] text-[25px] text-black ">
                    {props.programmerDetails.name}
                  </h4>
                  <span className="text-[15px]">{props.programmerDetails.position}</span>
                </div>
              </div>
              <div className="flex flex-row flex-wrap">
                <div className="font-bold text-[15px] text-black mr-2 mb-2">Skills: </div>
                {props.programmerDetails.skills.map((icon, index) => (
                  <div
                    key={index}
                    className="icon mr-2"
                  >
                    <i className={icon}></i>
                  </div>
                ))}
              </div>
              <p className="mb-3">{props.programmerDetails.description}</p>
            </div>
            <button
              className="px-close"
              onClick={props.closePopup}
            >
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default function TeamInformation() {
  const [showModal, setShowModal] = useState(0);
  const closePopup = () => {
    setShowModal(0);
  };
  const viewDetail = (member, index) => {
    // Show the popup
    setShowModal(index + 1);

    // Track the project view event
    if (!window.umami) { return }
    window.umami.track(`View member details: ${member}`);
  };
  return (
    <>
      <section
        className="section"
        id="members"
        data-scroll-data="2"
      >
        <h3 className=" font-[600] text-[40px] md:text-[60px] leading-[1.2] text-black pt-[30px] pb-[30px] lg:pt-[20px] lg:pb-[70px] text-center relative z-[1]">
          Our Team Members
        </h3>
        <div className="container">
          <Swiper
            spaceBetween={20}
            slidesPerView={2}
            modules={[Navigation]}
            loop={true}
            navigation={true}
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 5,
              },
            }}
          >
            {programmerList.map((val, i) => {
              return (
                <SwiperSlide key={i}>
                  <ProgrammerCard
                    name={val.name}
                    avatar={val.avatar[0]}
                    position={val.position}
                    skills={val.skills}
                    onClick={() => viewDetail(val.name, i)}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>
      {showModal ? (
        <>
          <ProgrammerDetail
            closePopup={closePopup}
            programmerDetails={programmerList[showModal - 1]}
          />
        </>
      ) : null}
    </>
  );
}
