export default function OurTeam() {
  return (
    <>
      <section
        className="home-section pt-[120px] pb-[80px] lg:pt-[180px] lg:pb-[100px] bg-orange-50 relative overflow-hidden"
        id="team"
        data-scroll-data="1"
      >
        <div className="container relative z-[1]">
          <h3 className=" font-[600] text-[40px] md:text-[60px] leading-[1.2] text-black pt-[30px] pb-[30px] lg:pt-[20px] lg:pb-[70px] text-center relative z-[1]">
            Meet Our Team
          </h3>
          <div className="grid lg:grid-cols-12 grid-cols-1 gap-16 items-center">
            <div className="lg:col-span-6">
              <div className="home-image text-center">
                <img
                  src="img/team.jpg"
                  title="Banner"
                  alt="Banner"
                  className="w-full"
                />
              </div>
            </div>
            <div className="lg:col-span-6">
              <p className="text-[16px] md:text-[18px] text-justify">
                Our team is comprised of highly skilled programmers who excel not just in their
                technical abilities, but also in their commitment to nurturing emerging talent
                through mentorship. We take immense pride in sharing our expertise, guiding aspiring
                developers through hands-on training, code reviews, and personalized coaching. Our
                mentorship program cultivates a collaborative environment that empowers mentees to
                hone their skills, overcome challenges, and develop a deep understanding of
                programming best practices. By investing in the growth of the next generation, we
                foster a culture of continuous learning and professional development, ensuring our
                mentees are well-equipped to make meaningful contributions to the ever-evolving tech
                landscape.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
