import { useState } from "react";
import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import { information } from "../../information";
/*--------------------
* Header Menu
----------------------*/
function Menu(props) {
  if (props.internal) {
    return (
      <li>
        <Link data-scroll-nav={props.id} smooth={true} to={props.link}>
          <span>{props.name}</span>
        </Link>
      </li>
    );
  } else {
    return (
      <li>
        <RouterLink to={props.link}>
          <span>{props.name}</span>
        </RouterLink>
      </li>
    );
  }
}

/*--------------------
* Header
----------------------*/
export default function Header({ menu }) {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  return (
    <>
      <header className="main-header fixed left-0 right-0 z-[111]">
        <div className="container flex items-center justify-between one-page-nav relative py-3">
          <div className="logo">
            <RouterLink smooth={true} to="/" className="cursor-pointer">
              <span className="flex w-60">
                <img
                  src="img/logo-only.png"
                  title="Logo"
                  alt="Logo"
                  className="logo-img mr-2"
                />
                <p className="header-company-name text-3xl my-auto text-black select-none">
                  {information.companyName}
                </p>
              </span>
            </RouterLink>
          </div>
          <button
            className="lg:hidden mobile_toggle w-[40px] flex flex-col"
            onClick={() => setIsOpenMenu(!isOpenMenu)}
          >
            <span className="w-[25px] h-[2px] bg-slate-900 inline-block"></span>
            <span className="w-[25px] h-[2px] bg-slate-900 inline-block my-[5px]"></span>
            <span className="w-[25px] h-[2px] bg-slate-900 inline-block"></span>
          </button>
          <div className={`navbar-collapse flex ${isOpenMenu ? "menu-open" : ""}`}>
            <ul className="navbar lg:mx-auto flex flex-col lg:flex-row p-4 lg:p-0 select-none">
              {menu.map((item) => <Menu key={item.id} {...item} />)}
            </ul>
          </div>
          <div className="ms-auto hidden lg:flex whitespace-nowrap">
            <Link
              className="px-btn px-btn-theme"
              data-scroll-nav="4"
              to="contactus"
            >
              Contact Us!
            </Link>
          </div>
        </div>
      </header>
    </>
  );
}
