import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { clientLogos } from "../../information";

function Brand(props) {
  return (
    <>
      <div className="p-3 text-center d-flex align-items-center justify-content-center w-100">
        <img
          className="h-14"
          src={props.brand_icon}
          title="Brand"
          alt="Brand"
        />
      </div>
    </>
  );
}

/*--------------------
* Brand Slider
----------------------*/
export default function BrandSlider() {
  return (
    <>
      <div className="bg-slate-900 pt-8 pb-4">
        <h1 className="text-white text-center text-4xl uppercase font-medium">
          our premium clients
        </h1>
        <hr className="mx-auto w-1/12 mt-3" />
        <div className="container">
          <Swiper
            spaceBetween={20}
            slidesPerView={5}
            breakpoints={{
              0: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 5,
              },
            }}>
            {clientLogos.map((val, i) => {
              return (
                <SwiperSlide key={i}>
                  <Brand brand_icon={val} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
}
